import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { Constants } from '../lib/const/constants';
import { PrimaryButton } from '../components/button';

function Pay() {
  const navigate = useNavigate();
  const [balances, setBalances] = useState([]);
  const [accountNumber, setAccountNumber] = useState("");
  const [sortcode, setSortcode] = useState("");
  const [balanceLoading, setBalanceLoading] = useState(false);
  const [usedBalances, setusedBalances] = useState([]);
  const [selectedValues, setselectedValues] = useState();


  const getData = async () => {
    const workspace = sessionStorage.getItem('login_workspaces');
    const login_id = sessionStorage.getItem('login_id');
    const staff_login_id = sessionStorage.getItem('staff_login_id');
    const login_token = sessionStorage.getItem('login_token');
    

    if (workspace) {
      const defaultBank = sessionStorage.getItem('defaultBank');
      const parsedWorkspace = JSON.parse(workspace);
      if (defaultBank === 'Clear Bank as Service') {
        setAccountNumber(sessionStorage.getItem('clearBankCustomerWalletNumber'));
        setSortcode(sessionStorage.getItem('clearBankCustomerSortCode'));
      } else {
        if (parsedWorkspace[0].accounts.meta.account_number) {
          setAccountNumber(parsedWorkspace[0].accounts.meta.account_number);
          setSortcode(parsedWorkspace[0].accounts.meta.routing_code);
        }
      }
    }

    if (!login_id || !login_token) {
      if (staff_login_id === '' || staff_login_id == null) {
        sessionStorage.clear();
        navigate('/StaffLogin');
      }
      else {
        navigate('/DebitTransactions')
      }
    }
  };

  const getBalances = async () => {
    if (balanceLoading) return;
    setBalanceLoading(true);
    console.log('loading getbalances');
    const login_workspaces_id = sessionStorage.getItem('login_workspaces_id');
    const login_token = sessionStorage.getItem('login_token');

    if (!login_workspaces_id) {
      const staff_login_id = sessionStorage.getItem('staff_login_id');
      if (staff_login_id === '' || staff_login_id == null) {
        navigate('/StaffLogin');
      }
      else {
        navigate('/DebitTransactions')
      }
    } else {
      try {
        const resp = await axios.get(Constants.BASE_URL + 'API-FX-161-BALANCES/' + login_workspaces_id, {
          headers: {
            Authorization: "Bearer " + JSON.parse(login_token),
            fx_key: Constants.SUBSCRIPTION_KEY
          }
        });

        const balArray = Object.values(resp.data);
        setusedBalances(resp.data.used_balances)
        balArray.pop();
        setBalanceLoading(false);
        setBalances(balances => [...balances, ...balArray]);
      } catch (err) {
        console.log(err.response.data);
      } finally {
        setBalanceLoading(false);
      }
    }
  };
  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  useEffect(() => {
    getData(0);
    getBalances();
  }, []);
  const setselectedValue = async(val) =>{
    if(val!=0)
    {
        setselectedValues(val)
    }
  }
  const navigateToScreen = async() =>{
    if(selectedValues!=0)
    {
        var val = JSON.parse(selectedValues);
        navigate("/currencyDashboard",{state:{currency:val.currency,balance:val.balance,flag:val.flag,currencyid:val.currencyid,accountNumber:val.accountNumber,sortcode:val.sortcode}})
    }
  }
  return (
    <div className="my-2">
     
      
        <div className="bg-white rounded-2xl my-4 shadow-lg">
          <div className="flex items-center justify-between px-8 pt-6 pb-1 text-black">
            <span className="font-medium text-lg border-l-4 border-l-[#F4CE14] pl-3">Select Currency</span>
          </div>

          <div className={`text-center overflow-x-auto space-x-5 px-4 py-4 w-full ${balanceLoading ? 'justify-center' : ''}`}>
          {balanceLoading ?
          <div className="text-center py-12">Loading...</div> : 
            <select className="bg-[#E7E6E6] border border-white text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 my-5" onChange={(e)=>setselectedValue(e.target.value)}>
                <option key={0} value={0}>---Select Currency---</option>
                {balances.map((resp, index) => {
                    const splitflag = (resp.meta.flag || '').split('/');
                    const flagname = splitflag.length > 1 ? Constants.FXMASTER_BASE_URL + 'flags/' + splitflag[splitflag.length - 1] : '';
                    const data = {
                      currency: resp.currency,
                      balance: resp.currency != null && resp.currency != '' && Object.prototype.hasOwnProperty.call(usedBalances, resp.currency) ? numberWithCommas(parseFloat((resp.balance - usedBalances[resp.currency]) < 0 ? '0' : resp.balance - usedBalances[resp.currency]).toFixed(2)) : numberWithCommas(parseFloat(resp.balance).toFixed(2)),
                      flag: flagname,
                      currencyid: resp.meta.country_id,
                      accountNumber: accountNumber,
                      sortcode: sortcode
                    };
                    return(
                        <option key={index} value={JSON.stringify(data)}>{resp.currency}</option>
                    )
                })}
            </select>}
            <PrimaryButton 
            label={'Proceed'}
            onClick={navigateToScreen}
            style={{width:150}}
            />
          </div>
        </div>
     
    </div>
  );
}

export default Pay;
